<template>
    <div>
        <AppAgileSlider />
        <AppWeAreAvailableWorldwide />
        <AppOurProfessionalServices />
        <!-- <AppWelcomeToOurAgency /> -->
        <!-- <AppStayInformed /> -->
    </div>
</template>
<script>
export default {
    components: {
        AppAgileSlider: () => import("@/components/AgileSlider.vue"),
        AppWeAreAvailableWorldwide: () => import("@/components/WeAreAvailableWorldwide.vue"),
        AppOurProfessionalServices: () => import("@/components/OurProfessionalServices.vue"),
        // AppWelcomeToOurAgency: () => import("@/components/WelcomeToOurAgency.vue"),
        // AppStayInformed: () => import("@/components/StayInformed.vue"),
    },
};
</script>